module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-breakpoints/gatsby-browser.js'),
      options: {"plugins":[],"queries":{"xs":"(max-width: 320px)","sm":"(max-width: 720px)","md":"(max-width: 1023px)","lg":"(max-width: 1536px)","desktop":"(min-width: 1024px)"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"pure":true,"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"The Mobile Shop","short_name":"TMS","start_url":"/","background_color":"#ffffff","theme_color":"#eb0000","display":"minimal-ui","icon":"src/images/favicon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"f51d49d5711c178bf4c29734ae9dfca2"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["GTM-P86B5P2"],"pluginConfig":{"head":false,"respectDNT":true,"delayOnRouteUpdate":0,"exclude":[],"origin":"https://www.googletagmanager.com"},"gtagConfig":{}},
    },{
      plugin: require('../node_modules/@pcf-engineering/gatsby-plugin-pcs-ada-chatbot/gatsby-browser.js'),
      options: {"plugins":[],"handle":"pcmobileshop","delay":0,"defer":true,"zIndex":1000,"dismissButton":true,"dismissDuration":"session","adaSettings":{}},
    },{
      plugin: require('../node_modules/@pcf-engineering/gatsby-plugin-akamai-bot-manager/gatsby-browser.js'),
      options: {"plugins":[],"whitelist":["themobileshop.ca"]},
    },{
      plugin: require('../node_modules/@pcf-engineering/gatsby-plugin-pcs-cookie-banner/gatsby-browser.js'),
      options: {"plugins":[],"domainScript":"45633dbc-df34-4cfe-bbd7-5f50fe41c5d2-test","publicFolder":"/builds/pcf-engineering/pc-services/tms/pcs-tms-publicsite/public","domainList":["www.themobileshop.ca",".themobileshop.ca","www.laboutiquemobile.ca",".laboutiquemobile.ca","www.uat.themobileshop.ca",".uat.themobileshop.ca"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
